import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Footer from '../components/Footer'
import Header from '../components/Header'
import picBanjo from '../images/Banjo_Kanna.jpg'
import picKuwamai from '../images/beet_lex.jpg'
import picEcochin from '../images/ecochin.png'
import picQujen from '../images/gozaru_naa.jpg'
import picHiron from '../images/hiron_rgkr.jpg'
import picSabakichi from '../images/knshtyk.jpg'
import picLilea from '../images/lileaLab.jpg'
import picMikka from '../images/mikkabouzu777.jpg'
import picMoz from '../images/momomo_moz.jpg'
import picSekine from '../images/sekine_kenta.jpg'
import picMasanage from '../images/tasklong.jpg'
import picTnohito from '../images/Tnohito1.png'
import picFukukozy from '../images/FUKUKOZY.jpeg'
import Logo from '../images/top_mainimage.png'

class StaffPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isArticleVisible: false,
            timeout: false,
            articleTimeout: false,
            article: '',
            loading: 'is-loading'
        }
        this.handleOpenArticle = this.handleOpenArticle.bind(this)
        this.handleCloseArticle = this.handleCloseArticle.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenArticle(article) {

        this.setState({
            isArticleVisible: !this.state.isArticleVisible,
            article
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                articleTimeout: !this.state.articleTimeout
            })
        }, 350)

    }

    handleCloseArticle() {

        this.setState({
            articleTimeout: !this.state.articleTimeout
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                isArticleVisible: !this.state.isArticleVisible,
                article: ''
            })
        }, 350)

    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isArticleVisible) {
                this.handleCloseArticle();
            }
        }
    }




    render() {
        return (
            <Layout location={this.props.location} >
                <div>
                    <Header />
                    <div id="staffpage">
                        <div className="staffsectiontitle">運営スタッフ</div>
                        <div className="staffdescription">クリックでそれぞれのTwitterアカウントにリンクします</div>
                    <div id="staff">
                        <div className="staffphoto">
                                <a href="https://twitter.com/Banjo_Kanna" target="_blank"><img src={picBanjo} alt="" width="180px"/>
                            <div class="mask">
                                <div class="caption">番匠カンナ</div>
                                    </div></a>
                                
                            </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/beet_lex" target="_blank"><img src={picKuwamai} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">クワマイ</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/ecochin" target="_blank"><img src={picEcochin} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">えこちん</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/gozaru_naa" target="_blank"><img src={picQujen} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">qujen</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/hiron_rgkr" target="_blank"><img src={picHiron} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">hiron</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/knshtyk" target="_blank"><img src={picSabakichi} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">sabakichi</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/lileaLab" target="_blank"><img src={picLilea} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">龍 lilea</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/mikkabouzu777" target="_blank"><img src={picMikka} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">三日坊主</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/momomo_moz" target="_blank"><img src={picMoz} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">moz</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/sekine_kenta" target="_blank"><img src={picSekine} alt="" width="180px"/>
                            <div class="mask">
                                <div class="caption">関根健太</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/tasklong" target="_blank"><img src={picMasanage} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">masanaga</div>
                            </div></a>
                        </div>
                        <div className="staffphoto">
                                <a href="https://twitter.com/Tnohito1" target="_blank"><img src={picTnohito} alt="" width="180px" />
                            <div class="mask">
                                <div class="caption">Tnohito</div>
                            </div></a>
                        </div>
                            <div className="staffphoto">
                                <a href="https://twitter.com/hukukozy" target="_blank"><img src={picFukukozy} alt="" width="180px" />
                                <div class="mask">
                                    <div class="caption">FUKUKOZY</div>
                                </div></a>
                            </div>
                        </div>
                        </div>
                    <div id="wrapper-footer">
                        <Footer timeout={this.state.timeout} />
                    </div>
                    <div id="bg"></div>
                </div>
                <Helmet>
                    <html lang="jp" />
                    <title>VRAA02運営スタッフ</title>
                    <meta name="description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta property="og:site_name" content="VRAA02" />
                    <meta property="og:image" content="https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="VRAA02" />
                    <meta name="twitter:description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta name="twitter:image" content="https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png" />
                </Helmet>
            </Layout>
        )
    }
}

export default StaffPage
